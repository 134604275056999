import React, { useMemo } from 'react';
import { shoppingCartOffersVar } from '@fingo/lib/apollo/reactive-variables/shopping-cart';
import currencyVar from '@fingo/lib/apollo/reactive-variables/money';
import { useReactiveVar } from '@apollo/client';
import { MY_OFFERS } from '@fingo/lib/graphql';
import { DocumentList } from '@fingo/lib/components/lists';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { AvailableOffersColumnsByCountry } from '../../../../constants';
import { NoDocumentOffersError } from './noDataMessages';

const NoDataError = () => <NoDocumentOffersError document="facturas" />;

const AvailableOffers = () => {
  const country = useGetCountryFromUrl();
  const includingColumns = useMemo(() => {
    if (country?.name) {
      return AvailableOffersColumnsByCountry[country.name];
    }
    return [];
  }, [country]);
  const shoppingCartOffers = useReactiveVar(shoppingCartOffersVar);
  const offerIds = shoppingCartOffers.map((offer) => offer.invoiceId);
  const currency = useReactiveVar(currencyVar);

  return (
    <DocumentList
      trackerId="OFFERS"
      type="available-offers"
      headerTitle="Ofertas"
      queryDocument={MY_OFFERS}
      checkboxSelection
      shoppingCartPreview
      emitter
      countryFilter
      includeHeaders={includingColumns}
      mobileHeaders={[
        'folio',
        'receiver_sortable',
        'offerMonthlyRate',
        'offerDefaultRate',
        'dateToPay',
      ]}
      customVariables={{
        currency,
        hasValidOffer: true,
        dateIssued_Gte: null,
        dateIssued_Lte: null,
      }}
      onSelectionModelChange={(ids) => {
        const newSelectedInvoices = ids.map((id) => {
          const existingOffer = (
            shoppingCartOffers.find(({ invoiceId }) => invoiceId === id)
          );
          return existingOffer || { invoiceId: id, dateToPay: null };
        });
        shoppingCartOffersVar(newSelectedInvoices);
      }}
      selectionModel={offerIds}
      keepNonExistentRowsSelected
      preColumns={useInvoicePreColumns()}
      isRowSelectable={({ row }) => !row.isForOrdering}
      isCellEditable={({ row }) => !row.isForOrdering}
      noRowsMessage={NoDataError}
    />
  );
};

export default AvailableOffers;
