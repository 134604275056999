import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

const NoEvaluatingError = () => (
  <>
    <Typography variant="h4" paragraph>
      Aún no posees facturas en evaluación
    </Typography>
    <Typography variant="body1" paragraph>
      Envía a evaluar en la{' '}
      <Link
        component={RouterLink}
        to="/app/sales/factoring/available"
      >
        pestaña de facturas disponibles
      </Link>
      {' '}y obtén una evaluación rápida. ¡Desbloquea el potencial de tu negocio hoy mismo!
    </Typography>
  </>
);

export default NoEvaluatingError;
