import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';

const NoCessionStatusError = () => (
  <>
    <Typography variant="h4" sx={{ maxWidth: 500 }} paragraph>
      No posees facturas disponibles para mostrar en el Estado de cesiones
    </Typography>
    <Typography variant="body1" paragraph>
      En esta página se muestran las facturas que ya has operado con nosotros.
    </Typography>
    <Typography variant="body1" paragraph>
      Para evaluar tus facturas y operarlas con Fingo, revisa {' '}
      <Link component={RouterLink} to="/app/sales/factoring/available">tus facturas disponibles.</Link>
    </Typography>
  </>
);

export default NoCessionStatusError;
