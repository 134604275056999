import React, { useState } from 'react';
import { DocumentList } from '@fingo/lib/components/lists';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import { CLIENT_ASSIGNMENTS } from '@fingo/lib/graphql';
import { useIsMobile } from '@fingo/lib/hooks';
import { useReactiveVar } from '@apollo/client';
import currencyVar from '@fingo/lib/apollo/reactive-variables/money';
import { CessionsSummaryProvider } from '../../../../components/factoring/CessionsSummary';
import { NoCessionStatusError } from './noDataMessages';
import CompanyRestrictionsButton from '../../../../components/buttons/CompanyRestrictions';

const CessionStatus = () => {
  const isMobile = useIsMobile();
  const [cessionInvoices, setCessionInvoices] = useState({});
  const currency = useReactiveVar(currencyVar);

  return (
    <DocumentList
      trackerId="CESSION_STATUS"
      type="cession-status"
      headerTitle="Estado de cesiones"
      queryDocument={CLIENT_ASSIGNMENTS}
      initialOrderBy="dateToPay"
      emitter
      countryFilter
      includeHeaders={[
        'folio',
        'dateIssued',
        'receiver_sortable',
        'amountWithIva',
        'paymentAmountAfterNegativeSurplus',
        'offerMonthlyRate',
        'offerDefaultRate',
        'operationDateToPay',
        'cessionStatus',
        'actionsDrawer',
      ]}
      mobileHeaders={['folio', 'receiver_sortable', 'cessionStatus']}
      allCompaniesOptions
      customVariables={{
        currency,
        hasBeenCeded: true,
        dateIssued_Gte: null,
        dateIssued_Lte: null,
        ...cessionInvoices,
      }}
      customSummaryElement={
        isMobile ? null : (
          <CessionsSummaryProvider setCessionInvoices={setCessionInvoices} />
        )
      }
      preColumns={useInvoicePreColumns()}
      noRowsMessage={NoCessionStatusError}
      density="comfortable"
      extraHeaderComponent={<CompanyRestrictionsButton />}
      sx={{
        '& .MuiDataGrid-main': {
          overflow: 'unset',
        },
        '& .MuiDataGrid-columnHeaders': {
          position: 'sticky',
          backgroundColor: 'white',
          zIndex: 999,
        },
        '& .MuiDataGrid-virtualScroller': {
          marginTop: '0!important',
        },
      }}
    />
  );
};

export default CessionStatus;
